import { Layout, Menu, Breadcrumb, Badge } from "antd";
import {
  SolutionOutlined,
  PictureOutlined,
  ClearOutlined,
  FileTextOutlined,
  HomeOutlined,
  QuestionOutlined,
  CommentOutlined,
  TeamOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { BiLogOut } from "react-icons/bi";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useRouteMatch } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";

import { useQuery } from "react-query";

import apiCommentCount from "../api/commentCount";
import apiRentOrderCount from "../api/rentOrderCount";
import apiCleaningCount from "../api/cleaningCount";

const { Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const DefaultLayout = (props: any) => {
  const { logout } = useAuth();
  const name = localStorage.getItem("name");
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
  };
  const cleanOrder = useQuery("cleaning-count", () => apiCleaningCount.find());
  const commentOrder = useQuery("comment-count", () => apiCommentCount.find());
  const rentOrder = useQuery("rent-count", () => apiRentOrderCount.find());

  const match = useRouteMatch();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        // style={{ backgroundColor: "#a2d9bb" }}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div className="logo">
          <img src="/logo.png" className="w-3/5" />
        </div>
        <Menu theme="dark" defaultSelectedKeys={[match.path]} mode="inline">
          <Menu.Item key="/" icon={<PictureOutlined />}>
            <Link to={"/"}>Баннер</Link>
          </Menu.Item>

          <SubMenu
            key="rent"
            icon={<HomeOutlined />}
            title={
              <span className="space-x-5">
                <span>Түрээс</span>
                {rentOrder?.data?.data !== 0 && (
                  <Badge
                    count={rentOrder?.data?.data}
                    style={{ backgroundColor: "green", borderColor: "green" }}
                  />
                )}
              </span>
            }
          >
            <Menu.Item key="/apartmentrent" icon={<HomeOutlined />}>
              <Link to={"/apartmentrent"}>Орон сууц түрээс</Link>
            </Menu.Item>
            <Menu.Item key="/servicerent" icon={<HomeOutlined />}>
              <Link to={"/servicerent"}>Талбайн түрээс</Link>
            </Menu.Item>
            <Menu.Item key="/rentorder" icon={<SolutionOutlined />}>
              <span className="space-x-5">
                <Link to={"/rentorder"}>Захиалгууд</Link>
                {rentOrder?.data?.data !== 0 && (
                  <Badge
                    count={rentOrder?.data?.data}
                    style={{ backgroundColor: "green", borderColor: "green" }}
                  />
                )}
              </span>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="cleaning"
            icon={<ClearOutlined />}
            title={
              <span className="space-x-5">
                <span>Цэвэрлэгээ</span>
                {cleanOrder?.data?.data !== 0 && (
                  <Badge
                    count={cleanOrder?.data?.data}
                    style={{ backgroundColor: "green", borderColor: "green" }}
                  />
                )}
              </span>
            }
          >
            <Menu.Item key="/cleaningapartment" icon={<ClearOutlined />}>
              <Link to={"/cleaningapartment"}>Айл гэр цэвэрлэгээ</Link>
            </Menu.Item>
            <Menu.Item key="/cleaningservice" icon={<ClearOutlined />}>
              <Link to={"/cleaningservice"}>Байгууллага цэвэрлэгээ</Link>
            </Menu.Item>
            <Menu.Item key="/cleaningOrder" icon={<SolutionOutlined />}>
              <span className="space-x-5">
                <Link to={"/cleaningOrder"}>Захиалгууд</Link>
                {cleanOrder?.data?.data !== 0 && (
                  <Badge
                    count={cleanOrder?.data?.data}
                    style={{ backgroundColor: "green", borderColor: "green" }}
                  />
                )}
              </span>
            </Menu.Item>
          </SubMenu>
          {/* <SubMenu key="dry" icon={<SkinOutlined />} title="Хими цэвэрлэгээ">
            <Menu.Item key="/dry" icon={<SkinOutlined />}>
              <Link to={"/dry"}>Хими Цэвэрлэгээ</Link>
            </Menu.Item>
            <Menu.Item key="/drycleaning" icon={<SkinOutlined />}>
              <Link to={"/drycleaning"}>Угаалга</Link>
            </Menu.Item>
            <Menu.Item key="/drycontact" icon={<SkinOutlined />}>
              <Link to={"/drycontact"}>Хими Салбар</Link>
            </Menu.Item>
          </SubMenu> */}
          <Menu.Item key="/cv" icon={<FileTextOutlined />}>
            <Link to={"/cv"}>Анкет</Link>
          </Menu.Item>
          <Menu.Item key="/comment" icon={<CommentOutlined />}>
            <span className="space-x-5">
              <Link to={"/comment"}>Сэтгэгдлүүд</Link>
              {commentOrder?.data?.data !== 0 && (
                <Badge
                  count={commentOrder?.data?.data}
                  style={{ backgroundColor: "green", borderColor: "green" }}
                />
              )}
            </span>
          </Menu.Item>
          <Menu.Item key="/faq" icon={<QuestionOutlined />}>
            <Link to={"/faq"}>Асуулт, Хариулт</Link>
          </Menu.Item>

          <SubMenu
            key="affiliation"
            icon={<TeamOutlined />}
            title={<span>Хамтрагч байгууллага</span>}
          >
            <Menu.Item key="/affiliation" icon={<PictureOutlined />}>
              <Link to={"/affiliation"}>Лого</Link>
            </Menu.Item>
            <Menu.Item key="/affiliationImage" icon={<PictureOutlined />}>
              <Link to={"/affiliationImage"}>Зураг</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/job" icon={<PaperClipOutlined />}>
            <Link to={"/job"}>Ажлын байр</Link>
          </Menu.Item>

          <div className="h-1 w-full border-t border-white/20"></div>
          <div className="flex flex-col items-center space-y-2 p-5">
            <div className="w-10 h-10 bg-white/30 rounded-full"></div>
            <span className="flex-1 text-white text-sm">{name}</span>
            <a
              onClick={logout}
              className={"flex items-center text-red-500 hover:text-red-700"}
            >
              <BiLogOut size={20} className="inline-block mr-2" />
              гарах
            </a>
          </div>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>{props.children}</Content>
        <Footer style={{ textAlign: "center" }}>
          Steppe Link LLC ©{new Date().getFullYear()}.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
