const Rate = ({
  value = 0,
  starCount = 5,
  rate,
  disabled = false,
  setRate,
  ...otherProps
}: any) => {
  return (
    <div className="flex" {...otherProps}>
      {Array<number>(starCount)
        .fill(0)
        .map((_, idx) => (
          <button key={idx} disabled={disabled} aria-label="rate">
            <svg
              display="block"
              width={rate ? 40 : 20}
              height={rate ? 40 : 20}
              color={value >= idx + 1 ? "#F5A623" : "gray.200"}
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
            </svg>
          </button>
        ))}
    </div>
  );
};
export default Rate;
