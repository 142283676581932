import { Button, Switch, Table, Modal, Radio } from "antd";
import { CSVLink } from "react-csv";
import apiDryOrder from "../api/dryOrder";
import {
  EditFilled,
  DeleteOutlined,
  SortAscendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import CleaningOrderFrom from "../components/CleaningOrderForm";
import apiCleaningCount from "../api/cleaningCount";

const dryOrder = () => {
  const headers = [
    { label: "Илгээсэн огноо", key: "receivedDate" },
    { label: "Нэр", key: "name" },
    { label: "Утас 1", key: "phoneNumber1" },
    { label: "Утас 2", key: "phoneNumber2" },
    { label: " И-Мэйл", key: "email" },
    { label: "Талбайн хэмжээ", key: "area" },
    { label: "Хаяг", key: "address" },
    { label: "Огноо", key: "date" },
    { label: "Цаг", key: "time" },
    { label: "Давтамж", key: "repetition" },
    { label: "Мессеж", key: "message" },
    { label: "Үйлчилгээ", key: "service" },
    { label: "Төрөл", key: "type" },
    { label: "Тэмдэглэл", key: "note" },
  ];

  const [currentItem, setCurrentItem] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, refetch } = useQuery("cleaning-order", () =>
    apiDryOrder.find(0, 999)
  );
  const cleaningCount = useQuery("cleaning-count", () =>
    apiCleaningCount.find(0, 999)
  );

  const UpdateMutation = useMutation(apiDryOrder.update);
  const removeMutation = useMutation(apiDryOrder.remove);
  const onchange = async (row: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...row,
      id,
    });
    refetch();
    cleaningCount?.refetch();
  };
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Нэр", dataIndex: "name", key: "name" },
    { title: "Утас 1", dataIndex: "phoneNumber1", key: "phoneNumber1" },

    {
      title: "Тэмдэглэл",
      dataIndex: "note",
      key: "note",
      render: (note: any) => {
        return (
          <span>
            <div className="w-20 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {note}
            </div>
          </span>
        );
      },
    },
    {
      title: "Accept",
      width: 100,
      key: "accepted",
      render: (row: any, __: any, index: number) => {
        return (
          <div>
            {row.accepted === "0" && <>Pendding</>}
            {row.accepted === "1" && <>Accept</>}
            {row.accepted === "2" && <>Decline</>}
          </div>
        );
      },
    },
    {
      title: "Цэвэрлэсэн эсэх",
      width: 200,
      key: "cleaned",
      render: (row: any, __: any, index: number) => {
        return (
          <div>
            {row.cleaned === true && <>Цэвэрлсэн</>}
            {row.cleaned === false && <>Цэвэрлээгүй</>}
          </div>
        );
      },
    },

    {
      title: "Хаагдсан",
      width: 50,
      key: "closed",
      render: (row: any, __: any, index: number) => {
        return (
          <Switch
            disabled
            checked={row.cleaned === true || row.accepted === "2"}
          />
        );
      },
    },
    {
      title: "Уншсан эсэх",
      width: 150,
      key: "read",
      filters: [
        {
          text: "Уншсан",
          value: true,
        },
        {
          text: "Уншаагүй",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.read === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              const tmp = { ...row };
              delete tmp._id;
              if (tmp.read !== true) {
                tmp.read = true;
              } else {
                tmp.read = false;
              }
              onchange(tmp, row._id);
            }}
          >
            <div className={`${row?.read === true ? "" : "font-bold"}`}>
              {row?.read === true ? <div>Уншсан</div> : <div>Уншаагүй</div>}
            </div>
          </div>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 150,
      key: "editDelete",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EditFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: removeMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await removeMutation.mutateAsync(row?._id);
                    refetch();
                    cleaningCount?.refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <Title title="Цэвэрлэгээний захиалгууд" />
        <div className="flex space-x-5">
          {data?.data && (
            <CSVLink filename="Clean order" data={data?.data} headers={headers}>
              <div className="px-4 py-2 border bg-white"> Татах</div>
            </CSVLink>
          )}
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data}
        loading={
          isLoading || UpdateMutation?.isLoading || removeMutation?.isLoading
        }
        pagination={{
          pageSize: 10,
          // total:,
        }}
      />

      <Modal
        footer={false}
        width={800}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <CleaningOrderFrom
          item={currentItem}
          id={currentItem?._id}
          close={() => {
            refetch();
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default dryOrder;
