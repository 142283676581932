import {
  Form,
  Input,
  Button,
  Spin,
  message,
  Radio,
  Space,
  DatePicker,
} from "antd";
import { useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import apiAssets from "../../api/assets";
import apiBanners from "../../api/banners";
import Upload from "../lib";

const EditBanner = (props: any) => {
  const updatedDate = moment(new Date());
  const defaultDate = moment(updatedDate).format("MMMM Do YYYY, h:mm:ss a");
  const pages = [
    { title: "Үндсэн" },
    { title: "Бидний тухай" },
    { title: "Түрээс" },
    { title: "Цэвэрлэгээ" },
  ];
  const { data, id } = props;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const assetsMutation = useMutation(apiAssets.insert);
  const bannersMutation = useMutation(
    id ? apiBanners.update : apiBanners.insert,
    {
      onSuccess: () => {
        message.success("Амжилттай хадгалагдлаа");
        props?.close();
        form.resetFields();
        fileRef.current = undefined;
      },
      onError: (e: any) => {
        message.error(e?.message || "Хүсэлт явуулах үед алдаа гарлаа!");
      },
      onSettled: () => {
        queryClient.invalidateQueries("banners");
      },
    }
  );
  const fileRef = useRef<any>();

  const onFinish = async (values: any) => {
    const data = { ...values };

    if (typeof fileRef.current === "string") {
      data.imageUrl = fileRef.current;
    } else {
      const imageUrl = await assetsMutation.mutateAsync({
        type: "banners",
        file: fileRef.current,
      });

      data.imageUrl = imageUrl?.imageUrl;
    }
    bannersMutation.mutate({ id: id, ...data });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (data) {
      delete data.updatedDate;
      form.setFieldsValue({ updatedDate: defaultDate, ...data });
    } else {
      form.resetFields();
      fileRef.current = undefined;
    }
  }, [data]);

  return (
    <Spin spinning={bannersMutation.isLoading || assetsMutation.isLoading}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ updatedDate: defaultDate }}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={<div className="text-[14px] font-semibold mb-2">Гарчиг</div>}
          rules={[{ required: true, message: "Гарчиг оруулна уу !" }]}
        >
          <Input />
        </Form.Item>

        <div className="flex space-x-10 items-center">
          <div className="w-1/2">
            <Form.Item
              name="imageUrl"
              label={
                <div className="text-[14px] font-semibold mb-2">Зураг</div>
              }
              rules={[{ required: true, message: "Зураг сонгоно уу !" }]}
            >
              <Upload
                handle={(file: any) => {
                  fileRef.current = file;
                }}
              />
            </Form.Item>
          </div>
          <div className="w-1/2">
            <Form.Item
              name="page"
              label={
                <div className="text-[14px] font-semibold mb-2">Хуудас</div>
              }
              rules={[{ required: true, message: "Хуудсаа сонгоно уу !" }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  {pages.map((item) => (
                    <Radio name="page" value={item.title}>
                      {item.title}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        <Form.Item name="updatedDate">
          {/* <DatePicker disabled /> */}
        </Form.Item>
        <div className="flex space-x-5 items-start  justify-end">
          <Button
            size="large"
            onClick={() => {
              props?.close();
              form.resetFields();
            }}
            className="bg-gray-300 px-5 cursor-pointer"
          >
            Болих
          </Button>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button size="large" type="primary" htmlType="submit">
              Хадгалах
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};
export default EditBanner;
