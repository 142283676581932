import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import { AuthConsumer, AuthProvider } from "./contexts/AuthContext";
import useAuth from "./hooks/useAuth";
import routing from "./routing";
import DefaultLayout from "./components/Layout";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          rest?.noLayout ? (
            <Component />
          ) : (
            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          )
        ) : (
          <Redirect to={"/signin"} />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }: any) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? <Component {...props} /> : <Redirect to={"/"} />
      }
    />
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <AuthConsumer>
            {({ loaded }) =>
              loaded && (
                <Switch>
                  {/* <PublicRoute path="/signin" component={SignIn} exact />
                <PrivateRoute path="/" component={Dashboard} /> */}

                  {routing?.map((route) =>
                    route?.public ? (
                      <PublicRoute {...route} />
                    ) : (
                      <PrivateRoute {...route} />
                    )
                  )}
                </Switch>
              )
            }
          </AuthConsumer>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
