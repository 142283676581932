import { Button, Switch, Table, Modal } from "antd";
import { CSVLink } from "react-csv";
import apiRentOrder from "../api/rentOrder";
import {
  EditFilled,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import RentOrderForm from "../components/RentOrder";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import apiRentOrderCount from "../api/rentOrderCount";

export interface IItem {
  note: string;
  name: string;
  phone: string;
  email: string;
  message: string;
  receivedDate: string;
  _id?: string;
  id: string;
}

const RentOrder = () => {
  const headers = [
    { label: "name", key: "name" },
    { label: "property", key: "property" },
    { label: "phoneNumber", key: "phoneNumber" },
    { label: "emailAddress", key: "emailAddress" },
    { label: "message", key: "message" },
    { label: "receivedDate", key: "receivedDate" },
    { label: "note", key: "note" },
  ];

  const [currentItem, setCurrentItem] = useState<IItem | any>();
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, refetch } = useQuery("rent-order", () =>
    apiRentOrder.find(0, 999)
  );

  const rentCount = useQuery("rent-count", () =>
    apiRentOrderCount.find(0, 999)
  );

  const removeMutation = useMutation(apiRentOrder.remove);
  const UpdateMutation = useMutation(apiRentOrder.update);

  const onchange = async (row: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...row,
      id,
    });
    refetch();
    rentCount?.refetch();
  };
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Нэр", dataIndex: "name", key: "name" },
    { title: "Утас", dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: "И-Мэйл",
      dataIndex: "emailAddress",
      key: "emailAddress",
      render: (message: any) => {
        return (
          <span>
            <div className="w-32 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {message}
            </div>
          </span>
        );
      },
    },
    {
      title: "Байрны нэр",
      dataIndex: "property",
      key: "property",
    },
    // {
    //   title: "Mессеж",
    //   dataIndex: "message",
    //   key: "message",
    //   render: (message: any) => {
    //     return (
    //       <span>
    //         <div className="w-32 overflow-x-hidden whitespace-nowrap text-ellipsis">
    //           {message}
    //         </div>
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Илгээсэн огноо",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (receivedDate: any) => {
        return (
          <div>
            {receivedDate.includes("Z")
              ? moment(receivedDate).format("MMMM Do YYYY, h:mm:ss")
              : receivedDate}
          </div>
        );
      },
    },
    {
      title: "Тэмдэглэл",
      dataIndex: "note",
      key: "note",
      render: (note: any) => {
        return (
          <span>
            <div className="w-32 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {note}
            </div>
          </span>
        );
      },
    },
    {
      title: "Уншсан эсэх",
      width: 150,
      key: "read",
      filters: [
        {
          text: "Уншсан",
          value: true,
        },
        {
          text: "Уншаагүй",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.read === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              const tmp = { ...row };
              delete tmp._id;
              if (tmp.read !== true) {
                tmp.read = true;
              } else {
                tmp.read = false;
              }
              onchange(tmp, row._id);
            }}
          >
            <div className={`${row?.read === true ? "" : "font-bold"}`}>
              {row?.read === true ? <div>Уншсан</div> : <div>Уншаагүй</div>}
            </div>
          </div>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      key: "operation",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EditFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: removeMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await removeMutation.mutateAsync(row?._id);
                    refetch();
                    rentCount?.refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <Title title="Түрээсийн захиалгууд" />
        <div className="flex space-x-5">
          {data?.data && (
            <CSVLink filename="Rent order" data={data?.data} headers={headers}>
              <div className="px-4 py-2 border bg-white"> Татах</div>
            </CSVLink>
          )}
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data}
        loading={
          isLoading || UpdateMutation?.isLoading || removeMutation?.isLoading
        }
      />

      <Modal
        footer={false}
        visible={open}
        width={800}
        onCancel={async () => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <RentOrderForm
          item={currentItem}
          id={currentItem?._id}
          close={() => {
            refetch();
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default RentOrder;
