import { Form } from "antd";

import moment from "moment";
import Rate from "../Rate";

const CommentFrom = (prop: any) => {
  return (
    <div className="py-10">
      <div className="space-y-5 mb-5">
        <div className="flex space-x-10">
          <div className="font-semibold ">Илгээсэн огноо :</div>
          <div>
            {moment(prop?.item?.receivedDate).format("YYYY-MM-DD HH:MM")}
          </div>
        </div>
        <div className="flex space-x-10">
          <div className="font-semibold ">Нэр :</div>
          <div>{prop?.item?.name}</div>
        </div>
        <div className="flex space-x-10">
          <div className="font-semibold ">Хамаарах байгууллага :</div>
          <div>{prop?.item?.affiliation}</div>
        </div>
        <div className="flex space-x-10">
          <div className="font-semibold ">Үнэлгээ :</div>
          <Rate value={prop?.item?.rating} disabled />
        </div>
        <div className="flex space-x-10">
          <div className="font-semibold w-[150px]">Сэтгэгдэл :</div>
          <div>{prop?.item?.message}</div>
        </div>
      </div>
    </div>
  );
};
export default CommentFrom;
