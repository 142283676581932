import client from "../lib/client";

const api = (path: string, services = {}) => ({
  insert: async (data: any) => {
    const res = await client.post(path, data);
    if (res.data?.error !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },
  login: async (username: string, password: string) => {
    try {
      const res: any = await client.post(path, {
        username,
        password,
      });
      return { success: true, data: res?.data };
    } catch (e) {
      return { success: false, e };
    }
  },

  find: async (page: any = 1, limit = 10) => {
    const res = await client.get(path, {
      params: {
        limit: limit,
        offset: page,
      },
    });
    if (res.data?.error !== 0) {
      throw Error(res.data?.message);
    }
    return res.data;
  },
  findOne: async (id: string) => {
    const res = await client.get(`${path}/${id}`);
    if (res.data?.error !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },
  update: async (data: any) => {
    const { id, ...tmp } = data;
    const res = await client.patch(`${path}/${id}`, tmp);
    if (res.data?.error !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },
  remove: async (id: string) => {
    const res = await client.delete(`${path}/${id}`);
    if (res.data?.error !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },

  ...services,
});
export default api;
