const CvFrom = (prop: any) => {
  const headers = [
    { label: "Илгээсэн огноо", key: "receivedDate" },
    { label: " Сонирхож буй албан тушаал", key: "position" },
    { label: "Хүсч буй цалин", key: "salary" },
    { label: "Ажилд орох өдөр", key: "receivedJobDate" },
    { label: " Одоо эрхэлж буй ажил", key: "work" },

    { label: "Хүйс", key: "gender" },
    { label: "Утас 1", key: "phoneNumber1" },
    { label: "Утас 2", key: "phoneNumber2" },
    { label: "Төрсөн өдөр", key: "birthDate" },

    { label: " Хаалга байр", key: "house" },
    { label: " Хороо, дүүрэг", key: "street" },
    { label: " Аймаг, хот", key: "city" },

    {
      label: "Ерөнхий боловсрол элссэн огноо ",
      key: "generalEducationEndDate",
    },
    {
      label: " Ерөнхий боловсрол төгссөн огноо ",
      key: "generalEducationStartedDate",
    },
    { label: "Ерөнхий сургуулийн нэр", key: "generalEducationSchoolName" },

    {
      label: "Дээд боловсрол элссэн огноо ",
      key: "higherEducationStartedDate",
    },
    {
      label: " Дээд боловсрол төгссөн огноо ",
      key: "higherEducationEndDate",
    },
    { label: "Дээд сургуулийн нэр", key: "higherEducationSchoolName" },
    { label: "Дээд Мэргэжил", key: "deedMergejil" },
    { label: "Дээд Голч дүн", key: "deedGolch" },

    {
      label: "Мэргэшсэн сургууль элссэн огноо",
      key: "MergeshsenStartedDate",
    },
    {
      label: "Мэргэшсэн сургууль төгссөн огноо",
      key: "MergeshsenEndDate",
    },
    { label: "Мэргэшсэн сургуулийн нэр", key: "MergeshsenSurguuli" },
    { label: "Мэргэшсэн  Мэргэжил", key: "MergeshsenMergejil" },
    { label: "Мэргэшсэн Голч дүн", key: "MergeshsenGolch" },

    { label: "Байгууллагын нэр 1", key: "companyName1" },
    { label: "Үйл ажиллагааны чиглэл 1", key: "chiglel1" },
    { label: "Ажил Орсон огноо 1", key: "jobStartedDate1" },
    { label: "Ажил Гарсан огноо 1", key: "jobEndDate1" },
    { label: "Албан тушаал 1", key: "albanTushaal1" },
    { label: "Сарын үндсэн цалингийн хэмжээ 1", key: "sariinTsalin1" },

    { label: "Байгууллагын нэр 2", key: "companyName2" },
    { label: "Үйл ажиллагааны чиглэл 2", key: "chiglel2" },
    { label: "Ажил Орсон огноо 2", key: "jobStartedDate2" },
    { label: "Ажил Гарсан огноо 2", key: "jobEndDate2" },
    { label: "Албан тушаал 2", key: "albanTushaal2" },
    { label: "Сарын үндсэн цалингийн хэмжээ 2", key: "sariinTsalin2" },
  ];

  return (
    <div className="py-10">
      <div className="space-y-5 mb-5">
        <div className="flex items-start">
          <div className="w-1/2 space-y-4">
            <img src={prop?.item?.img} className="w-2/3 rounded-md" />
            <div className=" text-[24px] font-semibold">
              {prop?.item?.firstName} {prop?.item?.lastName}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div className="space-y-5">
            {headers.slice(0, 5).map((item: any, index: number) => (
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold ">
                  {item?.label} :
                </div>
                <div className="text-md">{prop.item[item?.key]}</div>
              </div>
            ))}
            <div className="text-[24px] font-semibold">Ерөнхий мэдээлэл</div>
            {headers.slice(5, 12).map((item: any, index: number) => (
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold ">
                  {item?.label} :
                </div>
                <div className="text-md">{prop.item[item?.key]}</div>
              </div>
            ))}
            <div className="text-[24px] font-semibold">Боловсрол</div>

            {headers?.slice(12, 25)?.map((item: any, index: number) => (
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold ">
                  {item?.label} :
                </div>
                <div className="text-md">{prop.item[item?.key]}</div>
              </div>
            ))}
          </div>
          <div className="space-y-5">
            <div className="text-[24px] font-semibold">Ажилласан байдал</div>
            {headers
              ?.slice(25, headers.length)
              ?.map((item: any, index: number) => (
                <div className="flex space-x-5 items-center">
                  <div className="text-[14px] font-semibold ">
                    {item?.label} :
                  </div>
                  <div className="text-md">{prop.item[item?.key]}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CvFrom;
