import {
  Form,
  Input,
  Button,
  Spin,
  message,
  Radio,
  Space,
  DatePicker,
} from "antd";
import { useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import apiFaq from "../../api/faq";
import Upload from "../lib";

const EditFaq = (props: any) => {
  const pages = [
    { title: "Үндсэн хуудас" },
    { title: "Түрээсийн хуудас" },
    { title: "Цэвэрлэгээний хуудас" },
  ];
  const { data, id } = props;

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const faqMutation = useMutation(id ? apiFaq.update : apiFaq.insert, {
    onSuccess: () => {
      message.success("Амжилттай хадгалагдлаа");
      props?.close();
      form.resetFields();
      fileRef.current = undefined;
      props?.refetch();
    },
    onError: (e: any) => {
      message.error(e?.message || "Хүсэлт явуулах үед алдаа гарлаа!");
    },
    onSettled: () => {
      queryClient.invalidateQueries("banners");
    },
  });
  const fileRef = useRef<any>();

  const onFinish = async (values: any) => {
    const data = { ...values };

    if (typeof fileRef.current === "string") {
      data.imageUrl = fileRef.current;
    } else {
    }
    faqMutation.mutate({ id: id, ...data });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (data) {
      delete data.updatedDate;
      form.setFieldsValue({ ...data });
    } else {
      form.resetFields();
      fileRef.current = undefined;
    }
  }, [data]);
  console.log("form", form.getFieldsValue());

  return (
    <Spin spinning={faqMutation.isLoading || faqMutation.isLoading}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="question"
          label={<div className="text-[14px] font-semibold mb-2">Асуулт</div>}
          rules={[{ required: true, message: "Асуулт оруулна уу !" }]}
        >
          <Input className="py-2" />
        </Form.Item>
        <Form.Item
          name="answer"
          label={<div className="text-[14px] font-semibold mb-2">Хариулт</div>}
          rules={[{ required: true, message: "Хариулт оруулна уу !" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="page"
          label={<div className="text-[14px] font-semibold mb-2">Хуудас</div>}
          rules={[{ required: true, message: "Хуудсаа сонгоно уу !" }]}
        >
          <Radio.Group>
            <Space direction="vertical">
              {pages.map((item) => (
                <Radio name="page" value={item.title}>
                  {item.title}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="updatedDate">
          {/* <DatePicker disabled /> */}
        </Form.Item>
        <div className="flex space-x-5 items-start  justify-end">
          <Button
            size="large"
            onClick={() => {
              props?.close();
              form.resetFields();
            }}
            className="bg-gray-300 px-5 cursor-pointer"
          >
            Болих
          </Button>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button size="large" type="primary" htmlType="submit">
              Хадгалах
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};
export default EditFaq;
