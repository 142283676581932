import SignIn from "./pages/SignIn";
import ApartmetnRent from "./pages/ApartmentRent";
import ServiceRent from "./pages/ServiceRent";
import Banners from "./pages/Banners";
import RentOrder from "./pages/RentOrder";
import CleaningApartment from "./pages/ApartmentCleaning";
import CleaningService from "./pages/ServiceCleaning";
import Dry from "./pages/Dry";
import DryContact from "./pages/DryBranch";
import dryOrder from "./pages/dryOrder";
import drycleaning from "./pages/DryCleaning";
import Cv from "./pages/Cv";
import Comment from "./pages/Comment";
import Faq from "./pages/Faq";
import Affiliation from "./pages/Affiliation";
import AffiliationImage from "./pages/AffiliationImage";
import Job from "./pages/Job";

export default [
  {
    path: "/affiliation",
    component: Affiliation,
    exact: true,
  },
  {
    path: "/AffiliationImage",
    component: AffiliationImage,
    exact: true,
  },

  {
    path: "/apartmentrent",
    component: ApartmetnRent,
    exact: true,
  },
  {
    path: "/servicerent",
    component: ServiceRent,
    exact: true,
  },
  {
    path: "/cleaningOrder",
    component: dryOrder,
    exact: true,
  },
  {
    path: "/dry",
    component: Dry,
    exact: true,
  },
  {
    path: "/cv",
    component: Cv,
    exact: true,
  },
  {
    path: "/comment",
    component: Comment,
    exact: true,
  },
  {
    path: "/faq",
    component: Faq,
    exact: true,
  },
  {
    path: "/job",
    component: Job,
    exact: true,
  },
  {
    path: "/drycontact",
    component: DryContact,
    exact: true,
  },
  {
    path: "/cleaningapartment",
    component: CleaningApartment,
    exact: true,
  },
  {
    path: "/cleaningservice",
    component: CleaningService,
    exact: true,
  },
  {
    path: "/rentorder",
    component: RentOrder,
    exact: true,
  },
  {
    path: "/signin",
    component: SignIn,
    exact: true,
    public: true,
  },
  {
    path: "/",
    component: Banners,
    exact: true,
  },
];
