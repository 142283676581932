import { Button, message, Switch, Table, Modal, Spin } from "antd";

import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import DryForm from "../components/DryForm";
import { useMutation, useQuery } from "react-query";
import apiDry from "../api/dryBrancg";
import DryBranchFrom from "../components/DryBranch";

export interface IItem {
  name: string;
  price: number;
  _id?: string;
  id?: number;
}
const DryBranchPage = () => {
  const [currentItem, setCurrentItem] = useState<IItem | any>();
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, refetch } = useQuery("dry", () =>
    apiDry.find(0, 999)
  );
  const [formLoading, setFormLoading] = useState(false);
  const dryRemoveMutation = useMutation(apiDry.remove);
  const UpdateMutation = useMutation(apiDry.update);
  const active = async (item: any, id: any) => {
    setFormLoading(true);
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    refetch();
    setFormLoading(false);
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Нэр", dataIndex: "name", key: "name" },
    { title: "Утас", dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: "Цагийн хуваарь",
      dataIndex: "schedule",
      key: "schedule",
    },
    { title: "Хаяг", dataIndex: "address", key: "address" },

    {
      title: "Төлөв",
      width: 100,
      key: "active",
      filters: [
        {
          text: "active",
          value: true,
        },
        {
          text: "inactive",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.active === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.active === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.active === true) {
                  tmp.active = false;
                } else {
                  tmp.active = true;
                }
                active(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: " Үйлдэл",
      width: 100,
      key: "operation",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EditFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: dryRemoveMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await dryRemoveMutation.mutateAsync(row?._id);
                    refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {formLoading && (
        <div
          className={`absolute z-50 top-0 right-0 bottom-0 left-0 bg-white/80 flex items-center justify-center`}
        >
          <Spin />
        </div>
      )}
      <div className="flex justify-between items-center">
        <Title title="Хими цэвэрлэгээний салбар" />
        <div className="flex space-x-5">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentItem(null);
              setOpen(!open);
            }}
          >
            Салбар нэмэх
          </Button>
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        key={Math.random()}
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading}
      />

      <Modal
        footer={false}
        width={1000}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <DryBranchFrom
          data={currentItem}
          id={currentItem?._id}
          close={() => {
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </div>
  );
};

export default DryBranchPage;
