import { Button, Switch, Table, Modal, Radio } from "antd";

import {
  DeleteOutlined,
  SortAscendingOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";

import apiFaq from "../api/faq";

import FaqForm from "../components/FaqForm";
import { FiEdit } from "react-icons/fi";
import moment from "moment";

const Faq = () => {
  const [currentItem, setCurrentItem] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, refetch } = useQuery("faq", () =>
    apiFaq.find(0, 999)
  );

  const cvOrderRemoveMutation = useMutation(apiFaq.remove);
  const UpdateMutation = useMutation(apiFaq.update, {
    onSettled: () => {},
  });
  const columns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },

    {
      title: "Асуулт",
      dataIndex: "question",
      key: "question",
      width: 250,
      render: (question: any) => {
        return (
          <span>
            <div className="w-52 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {question}
            </div>
          </span>
        );
      },
    },
    {
      title: "Хариулт",
      dataIndex: "answer",
      key: "answer",
      width: 300,
      render: (answer: any) => {
        return (
          <span>
            <div className="w-64 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {answer}
            </div>
          </span>
        );
      },
    },
    {
      title: "Хуудас",
      dataIndex: "page",
      key: "page",
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Шинэчлэгдсэн огноо ",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (data: any) => {
        return moment(data).format("YYYY-MM-DD") || "-";
      },
    },
    {
      title: "Төлөв",
      width: 100,
      key: "active",
      filters: [
        {
          text: "active",
          value: true,
        },
        {
          text: "inactive",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.active === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.active === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.active === true) {
                  tmp.active = false;
                } else {
                  tmp.active = true;
                }
                active(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 150,
      key: "editDelete",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <FiEdit />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: cvOrderRemoveMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await cvOrderRemoveMutation.mutateAsync(row?._id);
                    refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];
  const active = async (item: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    // bannersMutation.mutate(page);
    refetch();
  };
  return (
    <>
      <div className="flex justify-between items-center">
        <Title title="Сэтгэгдлүүд" />
        <div className="flex space-x-5">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentItem(null);
              setOpen(!open);
            }}
          >
            Нэмэх
          </Button>
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading || UpdateMutation.isLoading}
        pagination={{
          pageSize: 10,
          // total:,
        }}
      />

      <Modal
        footer={false}
        width={1000}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <FaqForm
          data={currentItem}
          id={currentItem?._id}
          close={() => {
            setOpen(false);
            setCurrentItem(undefined);
          }}
          refetch={refetch}
        />
      </Modal>
    </>
  );
};

export default Faq;
