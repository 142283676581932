import React, { useEffect, useRef } from "react";
import { Input, Button, Form, message, Spin } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import { AiOutlineMinusCircle } from "react-icons/ai";
import apiJob from "../../api/job";

const JobForm = (prop: any) => {
  const { data, id, pos } = prop;

  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const jobMutation = useMutation(id ? apiJob.update : apiJob.insert, {
    onSuccess: () => {
      message.success("Амжилттай хадгалагдлаа");
      prop?.close();
      form.resetFields();
      prop?.refetch();
    },
    onError: (e: any) => {
      message.error(e?.message || "Хүсэлт явуулах үед алдаа гарлаа!");
    },
    onSettled: () => {
      queryClient.invalidateQueries("cleaning");
    },
  });
  const onFinish = async (values: any) => {
    const data = { ...values };

    jobMutation.mutate({ id: id, ...data });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    } else {
      form.resetFields();
    }
  }, [data]);
  return (
    <div className="pt-10">
      <Spin spinning={jobMutation.isLoading}>
        <Form
          form={form}
          name="cleaning"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ bullet1: [undefined] }}
        >
          <Form.Item
            name="name"
            label={<div className="text-[14px] font-semibold mb-2">Нэр</div>}
            rules={[{ required: true, message: "Нэр оруулна уу !" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <div className="text-md font-semibold mb-2">
                <span className="text-red-500 text-xs">*</span> Онцлог 1
              </div>
            }
          >
            <Form.List name="bullet1">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="flex items-center space-x-4">
                      <Form.Item
                        key={key}
                        {...restField}
                        name={[name, "text"]}
                        rules={[
                          { required: true, message: "Бөглөх шаардлагатай !" },
                        ]}
                        className="w-full"
                      >
                        <Input size="large" />
                      </Form.Item>
                      <AiOutlineMinusCircle
                        onClick={() => remove(name)}
                        size={20}
                        className="mb-5 cursor-pointer hover:opacity-80"
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Онцлог нэмэх
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            name="description1"
            label={
              <div className="text-[14px] font-semibold mb-2">
                Онцлог 1 тайлбар
              </div>
            }
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label={<div className="text-md font-semibold mb-2">Онцлог 2</div>}
          >
            <Form.List name="bullet2">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="flex items-center space-x-4">
                      <Form.Item
                        key={key}
                        {...restField}
                        name={[name, "text"]}
                        className="w-full"
                      >
                        <Input />
                      </Form.Item>
                      <AiOutlineMinusCircle
                        onClick={() => remove(name)}
                        size={20}
                        className="mb-5 cursor-pointer hover:opacity-80"
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Онцлог нэмэх
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            name="description2"
            label={
              <div className="text-[14px] font-semibold mb-2">
                Онцлог 2 тайлбар
              </div>
            }
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label={<div className="text-md font-semibold mb-2">Онцлог 3</div>}
          >
            <Form.List name="bullet3">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="flex items-center space-x-4">
                      <Form.Item
                        key={key}
                        {...restField}
                        name={[name, "text"]}
                        className="w-full"
                      >
                        <Input />
                      </Form.Item>
                      <AiOutlineMinusCircle
                        onClick={() => remove(name)}
                        size={20}
                        className="mb-5 cursor-pointer hover:opacity-80"
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Онцлог нэмэх
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            name="description3"
            label={
              <div className="text-[14px] font-semibold mb-2">
                Онцлог 3 тайлбар
              </div>
            }
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <div className="flex space-x-5 items-start  justify-end">
              <Button
                size="large"
                onClick={() => {
                  prop?.close();
                  form.resetFields();
                }}
                className="bg-gray-300 px-5  cursor-pointer"
              >
                Болих
              </Button>

              <Button size="large" type="primary" htmlType="submit">
                Хадгалах
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
export default JobForm;
