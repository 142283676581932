import { createContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const login = (user) => {
    setUser(user);
    setUserData(user);
  };

  const logout = () => {
    setUser(null);
    setUserData(null);
  };

  const setUserData = (user) => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  };

  const getUserData = () => {
    const tmp = localStorage.getItem("user");
    console.log("temp:", tmp);
    return tmp ? JSON.parse(tmp) : null;
  };

  useEffect(() => {
    setUser(getUserData());
    setLoaded(true);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, loaded }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
