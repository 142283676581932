import { Form, Input, Button, Checkbox, Card, Row, Col, message } from "antd";
import apilogin from "../api/login";
import useAuth from "../hooks/useAuth";

const SignIn = () => {
  const { login } = useAuth();
  const onFinish = async (values: any) => {
    // if (values.username === "admin" && values.password === "Admin123") {
    //   login({ username: values.username });
    //   message.success("Тавтай морил !" + values.username);
    // } else {
    //   message.error("Таны нэвтрэх нэр эсвэл нууц үг буруу байна !");
    // }

    const { username, password } = values;
    const res = await apilogin.login(username, password);

    if (res?.success) {
      login({ username: values.username });
      localStorage.setItem("name", values.username);
      message.success("Тавтай морил ! " + values.username);
    } else {
      message.error("Таны нэвтрэх нэр эсвэл нууц үг буруу байна !");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="relative">
      <img
        src="/signbg.jpg"
        className="absolute -z-1 top-0 left-0  w-full h-full hidden md:block"
      />
      <div className=" items-center md:flex justify-center px-10 opacity-95  bg-[#a2d9bb] h-screen relative">
        <img
          src="/logo.png"
          className=" md:w-96 w-60 mx-auto md:mx-0 pt-10 md:pt-0"
        />
        <div className="py-40 md:w-1/2 ">
          <Row justify="end">
            <Col md={16} lg={12}>
              <Card title="Нэвтрэх хэсэг">
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    // label="Хэрэглэгчийн нэр"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Нэвтрэх нэрээ оруулна уу !",
                      },
                    ]}
                  >
                    <Input placeholder="Хэрэглэгчийн нэр" size="large" />
                  </Form.Item>

                  <Form.Item
                    // label="Нууц үг"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Нэвтрэх нууц үгээ оруулна уу!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Нууц үг" size="large" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large">
                      Нэвтрэх
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
