import { Button, Switch, Table, Modal, Radio } from "antd";
import { CSVLink } from "react-csv";

import {
  EyeFilled,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";

import apiCv from "../api/cv";
import CvFrom from "../components/CvForm";

const Cv = () => {
  const headers = [
    { label: "Илгээсэн огноо", key: "receivedDate" },
    { label: "Овог", key: "firstName" },
    { label: "Нэр", key: "lastName" },
    { label: "Хүйс", key: "gender" },
    { label: "Утас 1", key: "phoneNumber1" },
    { label: "Утас 2", key: "phoneNumber2" },
    { label: "Төрсөн өдөр", key: "birthDate" },
    { label: "Ажилд орох өдөр", key: "receivedJobDate" },
    { label: "Хүсч буй цалин", key: "salary" },
    { label: " Сонирхож буй албан тушаал", key: "position" },
    { label: " Хаалга байр", key: "house" },
    { label: " Хороолол, гудамж", key: "street" },
    { label: " Аймаг, хот", key: "city" },
    { label: " Одоо эрхэлж буй ажил", key: "work" },
    {
      label: "Ерөнхий боловсрол элссэн огноо ",
      key: "generalEducationEndDate",
    },
    {
      label: " Ерөнхий боловсрол төгссөн огноо ",
      key: "generalEducationStartedDate",
    },
    { label: "Ерөнхий сургуулийн нэр", key: "generalEducationSchoolName" },

    {
      label: "Дээд боловсрол элссэн огноо ",
      key: "higherEducationStartedDate",
    },
    {
      label: " Дээд боловсрол төгссөн огноо ",
      key: "higherEducationEndDate",
    },
    { label: "Дээд сургуулийн нэр", key: "higherEducationSchoolName" },
    { label: "Дээд Мэргэжил", key: "deedMergejil" },
    { label: "Дээд Голч дүн", key: "deedGolch" },

    {
      label: "Зэрэг авсан огноо ",
      key: "MergeshsenStartedDate",
    },
    {
      label: "Хүчинтэй хугацаа",
      key: "MergeshsenEndDate",
    },
    { label: "Мэргэшсэн сургуулийн нэр", key: "MergeshsenSurguuli" },
    { label: "Мэргэшсэн  Мэргэжил", key: "MergeshsenMergejil" },
    { label: "Мэргэшсэн Голч дүн", key: "MergeshsenGolch" },

    { label: "Байгууллагын нэр 1", key: "companyName1" },
    { label: "Байгууллагын нэр 2", key: "companyName2" },

    { label: "Үйл ажиллагааны чиглэл 1", key: "chiglel1" },
    { label: "Үйл ажиллагааны чиглэл 2", key: "chiglel2" },

    { label: "Ажил Орсон огноо 1", key: "jobStartedDate1" },
    { label: "Ажил Гарсан огноо 1", key: "jobEndDate1" },

    { label: "Ажил Орсон огноо 2", key: "jobStartedDate2" },
    { label: "Ажил Гарсан огноо 2", key: "jobEndDate2" },

    { label: "Албан тушаал 1", key: "albanTushaal1" },
    { label: "Албан тушаал 2", key: "albanTushaal2" },

    { label: "Сарын үндсэн цалингийн хэмжээ 1", key: "sariinTsalin1" },
    { label: "Сарын үндсэн цалингийн хэмжээ 2", key: "sariinTsalin2" },
  ];

  const [currentItem, setCurrentItem] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, refetch } = useQuery("cv-order", () =>
    apiCv.find(0, 999)
  );

  const cvOrderRemoveMutation = useMutation(apiCv.remove);

  const columns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Овог", dataIndex: "lastName", key: "lastName" },
    { title: "Нэр", dataIndex: "firstName", key: "firstName" },
    {
      title: "Утас",
      dataIndex: "phoneNumber1",
      key: "phoneNumber1",
    },
    {
      title: "Сонирхож буй албан тушаал",
      dataIndex: "position",
      key: "position",
      width: 250,
    },
    {
      title: "Илгээсэн огноо",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: 210,
    },
    {
      title: "Үйлдэл",
      width: 150,
      key: "editDelete",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EyeFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: cvOrderRemoveMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await cvOrderRemoveMutation.mutateAsync(row?._id);
                    refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <Title title="Анкет" />
        <div className="flex space-x-5">
          {data?.data && (
            <CSVLink filename="cv" data={data?.data} headers={headers}>
              <div className="px-4 py-2 border bg-white"> Татах</div>
            </CSVLink>
          )}
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          pageSize: 10,
          // total:,
        }}
      />

      <Modal
        footer={false}
        width={1000}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <CvFrom
          item={currentItem}
          id={currentItem?._id}
          close={() => {
            refetch();
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default Cv;
