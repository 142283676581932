import { Button, message, Switch, Table, Modal, Spin } from "antd";
import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import ApartmentEditRent from "../components/ApartmentRentForm";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import apiRent from "../api/rents";
// import updateDataFilters from "../components/updateDataFilter";

export interface IItem {
  name: string;
  page: string;
  imageUrl: string;
  _id?: string;
  id?: number;
  active: boolean;
}
const RentPage = () => {
  const [currentItem, setCurrentItem] = useState<IItem | any>();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, refetch } = useQuery("rent", () =>
    apiRent.find(0, 999)
  );
  const rentsRemoveMutation = useMutation(apiRent.remove);
  const UpdateMutation = useMutation(apiRent.update);
  const rented = async (item: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    refetch();
  };
  const show = async (item: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    refetch();
  };
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Гарчиг", dataIndex: "name", key: "name" },

    {
      title: "Тухай",

      dataIndex: "about",
      width: 50,
      key: "about",
      render: (about: any) => {
        return (
          <span>
            <div className="w-32 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {about}
            </div>
          </span>
        );
      },
    },
    {
      title: "Мэдээлэл",
      dataIndex: "list",
      // width: 200,
      key: "list",
      render: (list: any) => {
        return (
          <span className="">
            {list?.map((d: any, i: any) => (
              <div className="w-40 overflow-x-hidden">
                {i <= 2 && (
                  <strong className="whitespace-nowrap font-light">
                    {d?.text}
                    <div className="w-full h-0.5 bg-gray-100 my-2 " />
                  </strong>
                )}
              </div>
            ))}
          </span>
        );
      },
    },
    {
      title: "Үнэ",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Түрээсэлсэн эсэх",
      // width: 50,
      key: "rented",
      filters: [
        {
          text: "Түрээсэлсэн",
          value: true,
        },
        {
          text: "Түрээслээгүй",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.rented === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.rented === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.rented === true) {
                  tmp.rented = false;
                } else {
                  tmp.rented = true;
                }
                rented(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Харуулах эсэх",
      // width: 100,
      key: "shown",
      filters: [
        {
          text: "Харуулах",
          value: true,
        },
        {
          text: "Нуух",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.shown === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.shown === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.shown === true) {
                  tmp.shown = false;
                } else {
                  tmp.shown = true;
                }
                show(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "editDelete",
      // width: 200,
      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EditFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: rentsRemoveMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await rentsRemoveMutation.mutateAsync(row?._id);
                    refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <Title title="Орон сууц Түрээс" />
        <div className="flex space-x-5">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentItem(null);
              setOpen(!open);
            }}
          >
            Орон сууц нэмэх
          </Button>
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data?.filter(
          (item: any) => item.type === "Орон сууц"
        )}
        loading={isLoading}
      />

      <Modal
        footer={null}
        width={1500}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <ApartmentEditRent
          data={currentItem}
          id={currentItem?._id}
          close={() => {
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default RentPage;
