import React, { useEffect, useRef } from "react";
import { Input, Button, Form, message, Spin } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import { AiOutlineMinusCircle } from "react-icons/ai";
import apiCleanig from "../../api/cleaning";
import apiAssets from "../../api/assets";
import Upload from "../lib";

const CleaningForm = (prop: any) => {
  const { data, id, pos, refetch } = prop;
  const fileRef = useRef<any>();
  const fileRef1 = useRef<any>();
  const fileRef2 = useRef<any>();
  const fileRef3 = useRef<any>();
  const [form] = Form.useForm();

  const assetsMutation = useMutation(apiAssets.insert);
  const queryClient = useQueryClient();
  const cleaningMutation = useMutation(
    id ? apiCleanig.update : apiCleanig.insert,
    {
      onSuccess: () => {
        message.success("Амжилттай хадгалагдлаа");
        prop?.close();
        form.resetFields();
      },
      onError: (e: any) => {
        message.error(e?.message || "Хүсэлт явуулах үед алдаа гарлаа!");
      },
      onSettled: () => {
        queryClient.invalidateQueries("cleaning");
      },
    }
  );
  const onFinish = async (values: any) => {
    const data = { ...values };
    console.log("data", data);

    if (typeof fileRef.current === "string") {
      data.imageUrl = fileRef.current;
    } else {
      const imageUrl = await assetsMutation.mutateAsync({
        type: "banners",
        file: fileRef.current,
      });
      data.imageUrl = imageUrl?.imageUrl;
    }
    if (fileRef1.current) {
      if (typeof fileRef1.current === "string") {
        data.imageUrl1 = fileRef1.current;
      } else {
        const imageUrl1 = await assetsMutation.mutateAsync({
          type: "banners",
          file: fileRef1.current,
        });
        data.imageUrl1 = imageUrl1?.imageUrl;
      }
    } else {
      data.imageUrl1 = "";
    }
    if (fileRef2.current) {
      if (typeof fileRef2.current === "string") {
        data.imageUrl2 = fileRef2.current;
      } else {
        const imageUrl2 = await assetsMutation.mutateAsync({
          type: "banners",
          file: fileRef2.current,
        });
        data.imageUrl2 = imageUrl2?.imageUrl;
      }
    } else {
      data.imageUrl2 = "";
    }
    if (fileRef3.current) {
      if (typeof fileRef3.current === "string") {
        data.imageUrl3 = fileRef3.current;
      } else {
        const imageUrl3 = await assetsMutation.mutateAsync({
          type: "banners",
          file: fileRef3.current,
        });
        data.imageUrl3 = imageUrl3?.imageUrl;
      }
    } else {
      data.imageUrl3 = "";
    }
    cleaningMutation.mutate({ id: id, ...data, type: prop?.type });

    refetch();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    } else {
      form.resetFields();
      fileRef.current = undefined;
    }
  }, [data]);
  return (
    <div className="py-10">
      <Spin spinning={cleaningMutation.isLoading || assetsMutation.isLoading}>
        <Form
          form={form}
          name="cleaning"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ bullet1: [undefined] }}
        >
          <Form.Item
            name="name"
            label={<div className="text-[14px] font-semibold mb-2">Гарчиг</div>}
            rules={[{ required: true, message: "Нэр оруулна уу !" }]}
          >
            <Input />
          </Form.Item>
          <div className="w-1/2">
            <Form.Item
              name="imageUrl"
              label={
                <div className="text-[14px] font-semibold mb-2">Зураг</div>
              }
              rules={[{ required: true, message: "Зураг сонгоно уу !" }]}
            >
              <Upload
                handle={(file: any) => {
                  fileRef.current = file;
                }}
              />
            </Form.Item>
          </div>

          <Form.Item
            label={
              <div className="text-md font-semibold mb-2">
                <span className="text-red-500 text-xs">*</span> Онцлог 1
              </div>
            }
          >
            <Form.List name="bullet1">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="flex items-center space-x-4">
                      <Form.Item
                        key={key}
                        {...restField}
                        name={[name, "text"]}
                        className="w-full"
                        rules={[
                          { required: true, message: "Бөглөх шаардлагатай !" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <AiOutlineMinusCircle
                        onClick={() => remove(name)}
                        size={20}
                        className="mb-5 cursor-pointer hover:opacity-80"
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Онцлог нэмэх
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            name="description1"
            label={
              <div className="text-[14px] font-semibold mb-2">
                Онцлог 1 тайлбар
              </div>
            }
          >
            <Input.TextArea />
          </Form.Item>
          <div className="w-1/2">
            <Form.Item
              name="imageUrl1"
              label={
                <div className="text-[14px] font-semibold mb-2">Зураг 1</div>
              }
              // rules={[{ required: true, message: "Зураг сонгоно уу !" }]}
            >
              <Upload
                handle={(file: any) => {
                  fileRef1.current = file;
                }}
                remove={() => {
                  fileRef1.current = undefined;
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            label={<div className="text-md font-semibold mb-2">Онцлог 2</div>}
          >
            <Form.List name="bullet2">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="flex items-center space-x-4">
                      <Form.Item
                        key={key}
                        {...restField}
                        name={[name, "text"]}
                        className="w-full"
                      >
                        <Input />
                      </Form.Item>
                      <AiOutlineMinusCircle
                        onClick={() => remove(name)}
                        size={20}
                        className="mb-5 cursor-pointer hover:opacity-80"
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Онцлог нэмэх
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            name="description2"
            label={
              <div className="text-[14px] font-semibold mb-2">
                Онцлог 2 тайлбар
              </div>
            }
          >
            <Input.TextArea />
          </Form.Item>
          <div className="w-1/2">
            <Form.Item
              name="imageUrl2"
              label={
                <div className="text-[14px] font-semibold mb-2">Зураг 2</div>
              }
              // rules={[{ required: true, message: "Зураг сонгоно уу !" }]}
            >
              <Upload
                handle={(file: any) => {
                  fileRef2.current = file;
                }}
                remove={() => {
                  fileRef2.current = undefined;
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            label={<div className="text-md font-semibold mb-2">Онцлог 3</div>}
          >
            <Form.List name="bullet3">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className="flex items-center space-x-4">
                      <Form.Item
                        key={key}
                        {...restField}
                        name={[name, "text"]}
                        className="w-full"
                      >
                        <Input />
                      </Form.Item>
                      <AiOutlineMinusCircle
                        onClick={() => remove(name)}
                        size={20}
                        className="mb-5 cursor-pointer hover:opacity-80"
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Онцлог нэмэх
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            name="description3"
            label={
              <div className="text-[14px] font-semibold mb-2">
                Онцлог 3 тайлбар
              </div>
            }
          >
            <Input.TextArea />
          </Form.Item>
          <div className="w-1/2">
            <Form.Item
              name="imageUrl3"
              label={
                <div className="text-[14px] font-semibold mb-2">Зураг 3</div>
              }
              // rules={[{ required: true, message: "Зураг сонгоно уу !" }]}
            >
              <Upload
                handle={(file: any) => {
                  fileRef3.current = file;
                }}
                remove={() => {
                  fileRef3.current = undefined;
                }}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex space-x-5 items-start  justify-end">
              <Button
                size="large"
                onClick={() => {
                  prop?.close();
                  form.resetFields();
                }}
                className="bg-gray-300 px-5  cursor-pointer"
              >
                Болих
              </Button>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button size="large" type="primary" htmlType="submit">
                  Хадгалах
                </Button>
              </Form.Item>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
export default CleaningForm;
