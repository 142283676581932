import {
  Form,
  Input,
  Button,
  Spin,
  message,
  Radio,
  Space,
  DatePicker,
} from "antd";
import { useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import apiAssets from "../../api/assets";
import apiAffiliation from "../../api/affiliation";
import Upload from "../lib";

const EditAffiliation = (props: any) => {
  const { data, id, refetch } = props;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const assetsMutation = useMutation(apiAssets.insert);
  const affiliationMutation = useMutation(
    id ? apiAffiliation.update : apiAffiliation.insert,
    {
      onSuccess: () => {
        message.success("Амжилттай хадгалагдлаа");
        props?.close();
        form.resetFields();
        fileRef.current = undefined;
      },
      onError: (e: any) => {
        message.error(e?.message || "Хүсэлт явуулах үед алдаа гарлаа!");
      },
      onSettled: () => {
        queryClient.invalidateQueries("banners");
      },
    }
  );
  const fileRef = useRef<any>();

  const onFinish = async (values: any) => {
    const data = { ...values };

    if (typeof fileRef.current === "string") {
      data.imageUrl = fileRef.current;
    } else {
      const imageUrl = await assetsMutation.mutateAsync({
        type: "banners",
        file: fileRef.current,
      });

      data.imageUrl = imageUrl?.imageUrl;
    }
    affiliationMutation.mutate({ id: id, ...data });
    refetch();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    } else {
      form.resetFields();
      fileRef.current = undefined;
    }
  }, [data]);

  return (
    <Spin spinning={affiliationMutation.isLoading || assetsMutation.isLoading}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={
            <div className="text-[14px] font-semibold mb-2">
              Байгууллагын нэр
            </div>
          }
          rules={[{ required: true, message: "Байгууллагын нэр уу !" }]}
        >
          <Input />
        </Form.Item>
        <div className="w-1/2">
          <Form.Item
            name="imageUrl"
            label={<div className="text-[14px] font-semibold mb-2">Зураг</div>}
            rules={[{ required: true, message: "Зураг сонгоно уу !" }]}
          >
            <Upload
              handle={(file: any) => {
                fileRef.current = file;
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="link"
          label={
            <div className="text-[14px] font-semibold mb-2">
              Байгууллагын линк
            </div>
          }
          rules={[{ required: true, message: "Байгууллагын нэр уу !" }]}
        >
          <Input />
        </Form.Item>
        <div className="flex space-x-5 items-start  justify-end">
          <Button
            size="large"
            onClick={() => {
              props?.close();
              form.resetFields();
            }}
            className="bg-gray-300 px-5 cursor-pointer"
          >
            Болих
          </Button>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button size="large" type="primary" htmlType="submit">
              Хадгалах
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};
export default EditAffiliation;
