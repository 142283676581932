import { Button, Table, Modal, InputNumber, message, Spin, Switch } from "antd";
import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import CleaningForm from "../components/CleaningApartmentForm";
import apiCleaning from "../api/cleaning";
import { useMutation, useQuery } from "react-query";
import apiCleanig from "../api/cleaning";
import moment from "moment";

export interface IItem {
  name: string;
  type: string;
  price: number;
  _id?: string;
  id?: number;
}
const ServiceCleaningPage = () => {
  const [currentItem, setCurrentItem] = useState<IItem | any>();
  const [open, setOpen] = React.useState(false);

  const UpdateMutation = useMutation(apiCleanig.update);

  const onFinish = async (value: any, row: any, id: any) => {
    let data = { ...row };
    data.position = parseInt(value.target.value);
    delete data._id;
    await UpdateMutation.mutateAsync({
      ...data,
      id,
    });
    message.success("Амжилттай засагдлаа !");
    refetch();
  };

  const { data, isLoading, refetch } = useQuery("cleaning", () =>
    apiCleaning.find(0, 999)
  );
  const active = async (item: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    // bannersMutation.mutate(page);
    refetch();
  };
  const cleaningRemoveMutation = useMutation(apiCleaning.remove);
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Гарчиг", dataIndex: "name", key: "name" },
    {
      title: "Зураг",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl: any) => {
        return (
          <div className="flex items-center space-x-4">
            <img src={`${imageUrl}`} className="h-16" />{" "}
            {/* <a href={`https://${imageUrl}`} target="_blank">
              {imageUrl}
            </a> */}
          </div>
        );
      },
    },
    {
      title: "Шинэчлэгдсэн огноо ",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (data: any) => {
        return moment(data).format("YYYY-MM-DD") || "-";
      },
    },
    {
      title: "Төлөв",
      width: 100,
      key: "active",
      filters: [
        {
          text: "active",
          value: true,
        },
        {
          text: "inactive",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.active === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.show === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.show === true) {
                  tmp.show = false;
                } else {
                  tmp.show = true;
                }
                active(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      key: "operation",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            {/* <InputNumber
              style={{ width: 60 }}
              defaultValue={row?.position}
              min={1}
              max={
                data?.data?.filter((el: any) => el.type === "Орон сууц").length
              }
              onPressEnter={(value) => onFinish(value, row, row._id)}
            /> */}
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EditFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: cleaningRemoveMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await cleaningRemoveMutation.mutateAsync(row?._id);
                    refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Spin spinning={UpdateMutation.isLoading}>
      <div className="flex justify-between items-center">
        <Title title="Байгууллага Цэвэрлэгээ" />
        <div className="flex space-x-5">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentItem(null);
              setOpen(!open);
            }}
          >
            Цэвэрлэгээ нэмэх
          </Button>
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />

      <Table
        bordered
        key={Math.random()}
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data?.filter(
          (item: any) => item.type === "Байгууллага"
        )}
        loading={isLoading}
      />

      <Modal
        footer={false}
        width={1000}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <CleaningForm
          type="Байгууллага"
          data={currentItem}
          pos={
            data?.data?.filter((el: any) => el.type === "Байгууллага").length
          }
          id={currentItem?._id}
          close={() => {
            setOpen(false);
            setCurrentItem(undefined);
          }}
          refetch={refetch}
        />
      </Modal>
    </Spin>
  );
};

export default ServiceCleaningPage;
