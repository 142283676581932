import { Button, message, Switch, Table, Modal } from "antd";

import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import DryForm from "../components/DryForm";
import { useMutation, useQuery } from "react-query";
import apiDry from "../api/dry";

export interface IItem {
  name: string;
  price: number;
  _id?: string;
  id?: number;
}
const DryPage = () => {
  const [currentItem, setCurrentItem] = useState<IItem | any>();
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, refetch } = useQuery("dry", apiDry.find);
  const dryRemoveMutation = useMutation(apiDry.remove);
  // console.log(
  //   data?.data.sort((a: any, b: any) => (a.color > b.color ? 1 : -1))
  // );

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    // {
    //   title: "Төрөл",
    //   dataIndex: "category",
    //   key: "category",
    //   sorter: (a: any, b: any) => a.category.localeCompare(b.category),
    // },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Зураг",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl: any) => {
        return (
          <div className="flex items-center space-x-4">
            <img src={`${imageUrl}`} className="h-16" />{" "}
            {/* <a href={`https://${imageUrl}`} target="_blank">
              {imageUrl}
            </a> */}
          </div>
        );
      },
    },
    // { title: "Үнэ", dataIndex: "price", key: "price" },
    {
      title: " Үйлдэл",
      width: 100,
      key: "operation",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EditFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: dryRemoveMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await dryRemoveMutation.mutateAsync(row?._id);
                    refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center">
        <Title title="Хими цэвэрлэгээ" />
        <div className="flex space-x-5">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentItem(null);
              setOpen(!open);
            }}
          >
            Хими цэвэрлэгээ нэмэх
          </Button>
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        key={Math.random()}
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data.filter((item: any) => item.type === "dry")}
        loading={isLoading}
      />

      <Modal
        footer={false}
        width={1000}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <DryForm
          data={currentItem}
          id={currentItem?._id}
          close={() => {
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </div>
  );
};

export default DryPage;
