import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Form,
  message,
  Radio,
  Space,
  InputNumber,
  Spin,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiAssets from "../../api/assets";
import apiRent from "../../api/rents";
import { PlusOutlined } from "@ant-design/icons";
import Upload from "../lib";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import UploadV2 from "../lib/Upload";

interface IProps {
  data?: any;
  id?: string;
  close: () => void;
}

const EditApartmentRent = ({ data, id, close }: IProps) => {
  const [loading, setLoading] = useState(false);
  const assetsMutation = useMutation(apiAssets.insert);
  const eventsMutation = useMutation(apiRent.insert);
  const UpdateMutation = useMutation(apiRent.update);
  const queryClient = useQueryClient();
  const picturesRef = useRef<any>({});
  const history = useHistory();
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const validateMessages = {
    required: "Бөглөх шаардлагатай!",
  };

  const onFinish = async (values: any) => {
    const formData = { ...values, type: "Орон сууц" };

    if (values?.imageUrls?.length && values?.list?.length) {
      setLoading(true);
      const imageUrls = picturesRef.current;

      const uploadsPromise = Object.values(imageUrls)
        ?.filter((image) => !!image)
        .map((file: any) => {
          if (typeof file === "string") {
            return Promise.resolve({ imageUrl: file });
          }

          return assetsMutation.mutateAsync({
            type: "banners",
            file,
          });
        });
      try {
        const images = await Promise.all(uploadsPromise);

        formData.imageUrls = Array.from(new Set(images));
        picturesRef.current = {};

        formData.list = formData.list?.map((p: any, index: any) => ({
          ...p,
          index,
        }));

        if (id) {
          await UpdateMutation.mutateAsync({
            ...formData,
            id,
          });
          message.success("Амжилттай засагдлаа !");
          form.resetFields();
          close();
        } else {
          await eventsMutation.mutateAsync(formData);
          message.success("Амжилттай үүслээ !");
          form.resetFields();
          close();
        }
        queryClient.invalidateQueries("rent");
        history.replace("/apartmentrent");
      } catch (e) {
        console.log("error: ", e);
        message.error("Уучлаарай хүсэлт явуулах үед алдаа гарлаа !");
      }
      setLoading(false);
    } else {
      message.warn("Зураг болон мэдээлэл оруулж өгнө үү");
    }
  };
  useEffect(() => {
    if (data) {
      const { list } = data;
      const { imageUrls } = data;

      form.setFieldsValue({
        ...data,
        imageUrls: imageUrls?.map((d: any) => ({
          ...d,
        })),
        list: list?.map((d: any) => ({
          ...d,
        })),
      });

      imageUrls
        ?.map((d: any, index: number) => [d.imageUrl, index])
        .forEach(([url, key]: any) => {
          if (picturesRef.current) {
            picturesRef.current[key] = url;
          } else {
            picturesRef.current = { [key]: url };
          }
        });
    } else {
      form.resetFields();
      picturesRef.current = {};
    }
  }, [data]);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        name="rent"
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        validateMessages={validateMessages}
        initialValues={{ type: "Apartment" }}
      >
        <Form.Item
          name="name"
          label={<div className="text-md font-semibold mb-2">Гарчиг</div>}
          rules={[{ required: true, message: "Гарчиг оруулна уу  !" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <div className="text-md font-semibold mb-2">
              <span className="text-red-500 text-xs">*</span> Зураг
            </div>
          }
        >
          <div className="w-full flex flex-wrap">
            <Form.List name="imageUrls">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div className="w-1/4 p-5">
                      <Form.Item
                        {...restField}
                        name={[name, "imageUrl"]}
                        rules={[{ required: true }]}
                        key={key}
                      >
                        <UploadV2
                          save={(file: any) => {
                            if (picturesRef.current && picturesRef.current) {
                              picturesRef.current[key] = file;
                            } else {
                              picturesRef.current = {};
                              picturesRef.current[key] = file;
                            }
                          }}
                          remove={() => {
                            picturesRef.current[name] = undefined;
                            remove(name);
                          }}
                        />
                      </Form.Item>
                      {/* <Button danger onClick={() => remove(name)}>
                      Устгах
                    </Button> */}
                    </div>
                  ))}
                  <div className="w-full">
                    <Button
                      size="large"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Зураг нэмэх
                    </Button>
                  </div>
                </>
              )}
            </Form.List>
          </div>
        </Form.Item>

        <Form.Item
          name="about"
          label={<div className="text-md font-semibold mb-2">Тайлбар</div>}
          rules={[{ required: true, message: "Тайлбар оруулна уу  !" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={
            <div className="text-md font-semibold mb-2">
              <span className="text-red-500 text-xs">*</span> Мэдээлэл
            </div>
          }
        >
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="flex items-center space-x-4">
                    <Form.Item
                      key={key}
                      {...restField}
                      name={[name, "text"]}
                      rules={[{ required: true }]}
                      className="w-full"
                    >
                      <Input />
                    </Form.Item>
                    <AiOutlineMinusCircle
                      onClick={() => remove(name)}
                      size={20}
                      className="mb-5 cursor-pointer hover:opacity-80"
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Мэдээлэл нэмэх
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          name="price"
          label={<div className="text-md font-semibold mb-2">Үнэ</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <div className="flex space-x-5 items-start  justify-end">
          <Button
            size="large"
            onClick={() => {
              close();
              form.resetFields();
            }}
            className="bg-gray-300 px-5  cursor-pointer"
          >
            Болих
          </Button>

          <Button size="large" type="primary" htmlType="submit">
            Хадгалах
          </Button>
        </div>
      </Form>
    </Spin>
  );
};
export default EditApartmentRent;
