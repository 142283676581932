import { Button, Switch, Table, Modal, Radio } from "antd";

import {
  EyeFilled,
  DeleteOutlined,
  SortAscendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";

import apiComment from "../api/comment";
import CommentFrom from "../components/CommentFrom";
import Rate from "../components/Rate";
import apiCommentDash from "../api/commentDashboard";
import moment from "moment";
import apiCommentCount from "../api/commentCount";

const Comment = () => {
  const [currentItem, setCurrentItem] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, refetch } = useQuery("comments", () =>
    apiCommentDash.find(0, 999)
  );
  const CommentCount = useQuery("comment-count", () =>
    apiCommentCount.find(0, 999)
  );
  const removeMutaion = useMutation(apiComment.remove);
  const UpdateMutation = useMutation(apiComment.update, {
    onSettled: () => {},
  });
  const onchange = async (row: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...row,
      id,
    });
    refetch();
    CommentCount?.refetch();
  };
  const columns = [
    {
      title: "#",
      key: "index",
      width: 40,
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Нэр", dataIndex: "name", key: "name" },
    {
      title: "Байгууллага",
      dataIndex: "affiliation",
      key: "affiliation",
    },
    {
      title: "Үнэлгээ",
      dataIndex: "rating",
      key: "rating",
      width: 50,
      render: (rating: any) => {
        return (
          <span>
            <div>
              <Rate value={rating} disabled />
            </div>
          </span>
        );
      },
    },

    // {
    //   title: "Сэтгэгдэл",
    //   dataIndex: "message",
    //   key: "message",
    //   width: 200,
    //   render: (about: any) => {
    //     return (
    //       <span>
    //         <div className="w-40 overflow-x-hidden whitespace-nowrap text-ellipsis">
    //           {about}
    //         </div>
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Илгээсэн огноо",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (about: any) => {
        return <span>{moment(about).format("YYYY-MM-DD HH:MM")}</span>;
      },
      width: 160,
    },
    {
      title: "Уншсан эсэх",
      width: 150,
      key: "read",
      filters: [
        {
          text: "Уншсан",
          value: true,
        },
        {
          text: "Уншаагүй",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.read === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              const tmp = { ...row };
              delete tmp._id;
              if (tmp.read !== true) {
                tmp.read = true;
              } else {
                tmp.read = false;
              }
              onchange(tmp, row._id);
            }}
          >
            <div className={`${row?.read === true ? "" : "font-bold"}`}>
              {row?.read === true ? <div>Уншсан</div> : <div>Уншаагүй</div>}
            </div>
          </div>
        );
      },
    },
    {
      title: "Төлөв",
      width: 70,
      key: "active",
      filters: [
        {
          text: "active",
          value: true,
        },
        {
          text: "inactive",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.active === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.show === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.show === true) {
                  tmp.show = false;
                } else {
                  tmp.show = true;
                }
                active(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 150,
      key: "editDelete",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EyeFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: removeMutaion.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await removeMutaion.mutateAsync(row?._id);
                    refetch();
                    CommentCount?.refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];
  const active = async (item: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    // bannersMutation.mutate(page);
    refetch();
  };
  return (
    <>
      <div className="flex justify-between items-center">
        <Title title="Сэтгэгдлүүд" />
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      <Table
        bordered
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data}
        loading={
          isLoading || UpdateMutation?.isLoading || removeMutaion?.isLoading
        }
        pagination={{
          pageSize: 10,
          // total:,
        }}
      />

      <Modal
        footer={false}
        width={1000}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <CommentFrom
          item={currentItem}
          id={currentItem?._id}
          close={() => {
            refetch();
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default Comment;
