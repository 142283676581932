import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Form,
  message,
  Radio,
  Space,
  InputNumber,
  Switch,
} from "antd";
import { useMutation } from "react-query";
import apiDryOrder from "../../api/dryOrder";
import moment from "moment";

const CleaningOrderFrom = (prop: any) => {
  const { item, id } = prop;

  const [form] = Form.useForm();
  const UpdateMutation = useMutation(apiDryOrder.update);
  const onFinish = async (values: any) => {
    const data = { ...values };
    await UpdateMutation.mutateAsync({
      ...data,
      id,
    });
    message.success("Амжилттай засагдлаа !");
    prop?.close();
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({ ...item, cleaned: item.cleaned ? true : false });
    }
  }, [item]);

  return (
    <div className="pt-10">
      <Form form={form} onFinish={onFinish}>
        <div className="space-y-5 mb-5">
          <Form.Item name="receivedDate">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold ">Илгээсэн Огноо:</div>
              <div className="text-md">{prop.item?.receivedDate}</div>
            </div>
          </Form.Item>
          <Form.Item name="name">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold ">Нэр:</div>
              <div className="text-md">{prop.item?.name}</div>
            </div>
          </Form.Item>
          <Form.Item name="email">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold ">И-Мэйл:</div>
              <div className="text-md">{prop.item?.email}</div>
            </div>
          </Form.Item>
          <Form.Item name="phoneNumber1">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold">Утас 1:</div>
              <div className="text-md">{prop.item?.phoneNumber1}</div>
            </div>
          </Form.Item>
          <Form.Item name="phoneNumber2">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold">Утас 2:</div>
              <div className="text-md">{prop.item?.phoneNumber2}</div>
            </div>
          </Form.Item>
          <Form.Item name="type">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold">Төрөл:</div>
              <div className="text-md">{prop.item?.type}</div>
            </div>
          </Form.Item>
          <Form.Item name="area">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold ">Талбайн хэмжээ:</div>
              <div className="text-md">{prop.item?.area}</div>
            </div>
          </Form.Item>
          <Form.Item name="date">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold">Огноо:</div>
              <div className="text-md">{prop.item?.date}</div>
            </div>
          </Form.Item>
          <Form.Item name="address">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold">Хаяг:</div>
              <div className="text-md">{prop.item?.address}</div>
            </div>
          </Form.Item>

          <Form.Item name="message">
            <div className="flex space-x-5 items-center">
              <div className="text-[14px] font-semibold ">Мессеж:</div>
              <div className="text-md">{prop.item?.message}</div>
            </div>
          </Form.Item>

          <Form.Item
            className="font-semibold items-center"
            label={
              <div className="text-[14px] font-semibold mb-2">Тэмдэглэл</div>
            }
            name="note"
            rules={[{ required: true, message: "Тэмдэглэлээ бөглөнө үү !" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="accepted"
            label={
              <div className="text-[14px] font-semibold mb-2 ">Үйлдэл</div>
            }
            rules={[{ required: true, message: "Төрөлөө сонгоно уу !" }]}
          >
            <Radio.Group>
              <Radio value={"1"}>Accept</Radio>
              <Radio value={"2"}>Decline</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="cleaned"
            label={<div className="text-[14px] font-semibold mb-2">Төлөв</div>}
            rules={[{ required: true, message: "Төрөлөө сонгоно уу !" }]}
          >
            <Radio.Group>
              <Radio value={true}>Цэвэрлэсэн</Radio>
              <Radio value={false}>Цэвэрлээгүй</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Form.Item>
          <div className="flex space-x-5 items-start  justify-end">
            <Button
              size="large"
              onClick={() => {
                prop?.close();
                form.resetFields();
              }}
              className="bg-gray-300 px-5 cursor-pointer"
            >
              Болих
            </Button>

            <Button size="large" type="primary" htmlType="submit">
              Хадгалах
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default CleaningOrderFrom;
