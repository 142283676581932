import client from "../lib/client";

const apiAssets = {
  insert: async ({ type, file }: any) => {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("image", file);
    const res = await client.post("/admin/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.data?.error !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },
};
export default apiAssets;
