import { Input, message, Tooltip } from "antd";
import * as React from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { ImUpload } from "react-icons/im";

interface IProps {
  value: any;
  onChange: (value: any) => void;
  save?: any;
}

const UploadV2 = (props: IProps | any) => {
  const [file, setFile] = React.useState<any>(null);
  const { value, onChange, save } = props;
  const [preview, setPreview] = React.useState<any>(undefined);
  const handleChange = (e: any) => {
    const [file] = e.target.files;
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size <= 1) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          onChange(file);
          setPreview(reader.result);
          save(file);
          setFile(file);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        message.warn(
          `Уучлаарай таны файлын хэмжээ ${size?.toFixed(
            2
          )}mb байна. 1mb аас бага байх ёстой!`
        );
      }
    }
  };

  React.useEffect(() => {
    if (!preview && value) {
      if (typeof value === "string") {
        setPreview(value);
      } else {
        setFile(value);
        const reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = function () {
          setPreview(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    }
  }, [value]);

  return (
    <>
      <div className="w-full border-2 relative h-20 group flex items-center justify-center hover:bg-gray-100/10 border-dashed p-5 text-center hover:border-blue-500/50 rounded-md">
        <input
          type="file"
          accept="image/*"
          name="file"
          onChange={handleChange}
          // onChange={(e) => {
          //   const [file]: any = e.target.files;
          //   setPreview(null);
          //   setTimeout(() => {
          //     if (file?.name) {
          //       setFile(file);
          //       handlePreview(file);
          //     } else {
          //       setFile(null);
          //       handlePreview(null);
          //     }
          //   }, 100);
          // }}
          className="absolute top-0 w-full h-full cursor-pointer left-0 right-0 bottom-0 opacity-0"
        />
        <button
          type="button"
          className="rounded-full  flex flex-col justify-center items-center px-10 py-2 bg-primary group-hover:text-blue-500 text-black/50"
        >
          <div className="flex items-center space-x-2">
            <ImUpload size={20} /> <span>Файл сонгох</span>
          </div>
          {file && (
            <div className="text-center w-full font-semibold text-xs">
              {file?.name}
            </div>
          )}
        </button>

        <Tooltip title="Устгах">
          <div
            onClick={() => {
              setFile(null);
              // props.onChange(undefined);
              setPreview(null);
              props?.remove && props?.remove();
            }}
            className=" h-10 top-1 bg-white group cursor-pointer right-1 absolute flex items-center justify-center"
          >
            <AiFillCloseSquare
              className={`text-red-500 group-hover:opacity-80`}
              size={36}
            />
          </div>
        </Tooltip>
      </div>
      {preview && (
        <div className="border-2 w-full border-t-0 border-dashed  relative -top-1 p-2">
          <img className="w-full" src={preview} />
          {/* <Tooltip title="Устгах">
          <div
            onClick={() => {
              setFile(null);
              // props.onChange(undefined);
              setPreview(null);
              remove && remove();
            }}
            className=" h-10 top-1 bg-white group cursor-pointer right-1 absolute flex items-center justify-center"
          >
            <AiFillCloseSquare
              className={`text-red-500 group-hover:opacity-80`}
              size={36}
            />
          </div>
        </Tooltip> */}
        </div>
      )}
    </>
  );
};

export default UploadV2;
