import React, { createRef, useEffect, useRef, useState } from "react";
import { Input, Button, Form, message, Radio, Space, InputNumber } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";
import apiAssets from "../../api/assets";

import apiDry from "../../api/dry";
import Upload from "../lib";

const DryForm = (prop: any) => {
  const { data, id } = prop;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const assetsMutation = useMutation(apiAssets.insert);
  const dryMutation = useMutation(id ? apiDry.update : apiDry.insert, {
    onSuccess: () => {
      message.success("Амжилттай хадгалагдлаа");
      prop?.close();
      form.resetFields();
    },
    onError: (e: any) => {
      message.error(e?.message || "Хүсэлт явуулах үед алдаа гарлаа!");
    },
    onSettled: () => {
      queryClient.invalidateQueries("dry");
    },
  });
  const fileRef = useRef<any>();
  const onFinish = async (values: any) => {
    Object.assign(values, { type: "dry" });

    const data = { ...values };
    Object.keys(values).forEach((key) => {
      data[key] =
        typeof values[key] === "string" ? values[key].trim() : values[key];
    });

    if (typeof fileRef.current === "string") {
      data.imageUrl = fileRef.current;
    } else {
      const imageUrl = await assetsMutation.mutateAsync({
        type: "banners",
        file: fileRef.current,
      });

      data.imageUrl = imageUrl?.imageUrl;
    }

    dryMutation.mutate({ id: id, ...data });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    } else {
      form.resetFields();
    }
  }, [data]);
  return (
    <div className="py-10">
      <Form
        form={form}
        name="dry"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ type: "dry" }}
        autoComplete="off"
      >
        {/* <Form.Item
          name="category"
          label={<div className="text-lg font-bold mb-2">Төрөл</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label={<div className="text-lg font-bold mb-2">Гарчиг</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<div className="text-lg font-bold mb-2">Үнэ</div>}
          name={"price"}
          rules={[{ required: true }]}
        >
          <InputNumber
            size="large"
            placeholder="0"
            style={{
              width: "100%",
            }}
          />
        </Form.Item> */}
        <Form.Item
          name="name"
          label={<div className="text-lg font-semibold mb-2">Гарчиг</div>}
          rules={[{ required: true, message: "Гарчиг оруулна уу !" }]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name="imageUrl"
          label={<div className="text-lg font-semibold mb-2">Зураг</div>}
          rules={[{ required: true, message: "Зураг сонгоно уу !" }]}
        >
          <Upload
            handle={(file: any) => {
              fileRef.current = file;
            }}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <div className="flex space-x-5 items-start  justify-end">
            <Button
              size="large"
              onClick={() => {
                prop?.close();
                form.resetFields();
              }}
              className="bg-gray-300 px-5  cursor-pointer"
            >
              Болих
            </Button>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button size="large" type="primary" htmlType="submit">
                Хадгалах
              </Button>
            </Form.Item>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default DryForm;
