import { Button, message, Switch, Table, Modal, Spin } from "antd";
import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import EditBanner from "../components/EditBanner";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import apiBanners from "../api/banners";
import { FiEdit } from "react-icons/fi";

export interface IItem {
  name: string;
  imageUrl: string;
  redirectType: string;
  redirectValue: string;
  _id?: string;
  id?: number;
  active: boolean;
}
const BannersPage = () => {
  const [currentItem, setCurrentItem] = useState<IItem | any>();
  const [formLoading, setFormLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState<any>();
  // const bannersMutation = useMutation("banners", apiBanners.find);
  const bannersQuery = useQuery(
    ["banners", page],
    () => apiBanners.find((page - 1) * 10),
    {
      retry: false,
    }
  );

  const bannersRemoveMutation = useMutation(apiBanners.remove);
  const UpdateMutation = useMutation(apiBanners.update, {
    onSettled: () => {},
  });
  const active = async (item: any, id: any) => {
    setFormLoading(true);
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    // bannersMutation.mutate(page);
    bannersQuery.refetch();
    setFormLoading(false);
  };

  React.useEffect(() => {
    // bannersMutation.mutate((page - 1) * 10);
  }, [page]);

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Гарчиг", dataIndex: "name", key: "name" },
    {
      title: "Зураг",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl: any) => {
        return (
          <div className="flex items-center space-x-4">
            <img src={`${imageUrl}`} className="h-16" />{" "}
            {/* <a href={`https://${imageUrl}`} target="_blank">
              {imageUrl}
            </a> */}
          </div>
        );
      },
    },
    {
      title: "Хуудас",
      dataIndex: "page",
      key: "page",
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Шинэчлэгдсэн огноо ",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Төлөв",
      width: 100,
      key: "active",
      filters: [
        {
          text: "active",
          value: true,
        },
        {
          text: "inactive",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.active === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.active === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.active === true) {
                  tmp.active = false;
                } else {
                  tmp.active = true;
                }
                active(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      key: "operation",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <FiEdit />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: bannersRemoveMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await bannersRemoveMutation.mutateAsync(row?._id);
                    bannersQuery.refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* {formLoading && (
        <div
          className={`absolute z-50 top-0 right-0 bottom-0 h-screen left-0 bg-white/80 flex items-center justify-center`}
        >
          <Spin />
        </div>
      )} */}

      <div className="flex justify-between items-center">
        <Title title="Баннер жагсаалт" />
        <div className="flex space-x-5">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentItem(null);
              setOpen(!open);
            }}
          >
            Баннер нэмэх
          </Button>
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />
      {/* <div className="bg-white border rounded overflow-hidden"> */}
      <Table
        bordered
        columns={columns}
        dataSource={bannersQuery?.data?.data || []}
        loading={
          bannersQuery.isLoading ||
          UpdateMutation.isLoading ||
          bannersRemoveMutation.isLoading
        }
        pagination={{
          current: bannersQuery?.data?.pagination?.offset || 1,
          pageSize: bannersQuery?.data?.pagination?.limit || 10,
          total: bannersQuery?.data?.pagination.total,
          onChange: (page) => {
            setPage(page);
            // // bannersMutation.mutate(offset * 10);
          },
        }}
      />
      {/* </div> */}

      <Modal
        footer={false}
        visible={open}
        width={1000}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <EditBanner
          data={currentItem}
          id={currentItem?._id}
          close={() => {
            setOpen(false);
            setCurrentItem(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default BannersPage;
