import { Button, Table, Modal, InputNumber, message, Spin, Switch } from "antd";
import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import * as React from "react";
import Title from "../components/Title";
import { useState } from "react";
import JobForm from "../components/JobForm";
import { useMutation, useQuery } from "react-query";
import apiJob from "../api/job";
import moment from "moment";
import apiJobDashboard from "../api/jobDashboard";

const Job = () => {
  const [currentItem, setCurrentItem] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, refetch } = useQuery("jobs-get-dashboard", () =>
    apiJobDashboard.find(0, 999)
  );

  const UpdateMutation = useMutation(apiJob.update);

  const active = async (item: any, id: any) => {
    await UpdateMutation.mutateAsync({
      ...item,
      id,
    });
    refetch();
  };
  const removeMutation = useMutation(apiJob.remove);
  const columns = [
    {
      title: "#",
      key: "index",
      width: 70,
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    { title: "Гарчиг", dataIndex: "name", key: "name" },

    {
      title: "Шинэчлэгдсэн огноо ",
      width: 200,
      dataIndex: "updated_at",
      key: "updated_at",
      render: (data: any) => {
        return moment(data).format("YYYY-MM-DD") || "-";
      },
    },
    {
      title: "Тогтмол ажлын байр",
      width: 150,
      key: "regular",
      filters: [
        {
          text: "active",
          value: true,
        },
        {
          text: "inactive",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.regular === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.regular === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.regular === true) {
                  tmp.regular = false;
                } else {
                  tmp.regular = true;
                }
                active(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Төлөв",
      width: 100,
      key: "active",
      filters: [
        {
          text: "active",
          value: true,
        },
        {
          text: "inactive",
          value: false,
        },
      ],

      onFilter: (value: any, record: any) => {
        return record.show === value;
      },
      filterIcon: () => {
        return (
          <div className="text-xl text-blue-900">
            <SortAscendingOutlined />
          </div>
        );
      },

      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Switch
              key={`switch-${row.id}`}
              checked={row.show === true}
              onClick={(e) => {
                const tmp = { ...row };
                delete tmp._id;
                if (tmp.show === true) {
                  tmp.show = false;
                } else {
                  tmp.show = true;
                }
                active(tmp, row._id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      key: "operation",

      render: (row: any, __: any, index: number) => {
        return (
          <div className="flex space-x-10 items-center">
            {/* <InputNumber
              style={{ width: 60 }}
              defaultValue={row?.position}
              min={1}
              max={
                data?.data?.filter((el: any) => el.type === "Орон сууц").length
              }
              onPressEnter={(value) => onFinish(value, row, row._id)}
            /> */}
            <Button
              onClick={() => {
                setCurrentItem({ ...row, id: index });
                setOpen(true);
              }}
            >
              <EditFilled />
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  content: "Та устгахдаа итгэлтэй байна уу ?",
                  icon: <ExclamationCircleOutlined />,
                  title: `${row?.name}`,
                  okButtonProps: {
                    danger: true,
                    loading: removeMutation.isLoading,
                  },
                  okText: "Устгах",
                  cancelText: "Үгүй",
                  async onOk() {
                    await removeMutation.mutateAsync(row?._id);
                    refetch();
                  },
                  onCancel() {},
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Spin spinning={UpdateMutation.isLoading}>
      <div className="flex justify-between items-center">
        <Title title="Ажлын байр" />
        <div className="flex space-x-5">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setCurrentItem(null);
              setOpen(!open);
            }}
          >
            Ажлын байр нэмэх
          </Button>
        </div>
      </div>

      <div className="h-0.5 mb-5 w-full bg-gray-300" />

      <Table
        bordered
        key={Math.random()}
        className="components-table-demo-nested"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading || UpdateMutation.isLoading}
      />

      <Modal
        footer={false}
        width={1000}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setCurrentItem(undefined);
        }}
      >
        <JobForm
          data={currentItem}
          id={currentItem?._id}
          close={() => {
            setOpen(false);
            setCurrentItem(undefined);
          }}
          refetch={refetch}
        />
      </Modal>
    </Spin>
  );
};

export default Job;
