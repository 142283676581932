import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Form,
  message,
  Radio,
  Space,
  InputNumber,
  Spin,
} from "antd";
import apiRentOrder from "../../api/rentOrder";
import { useMutation, useQueryClient } from "react-query";
import apiAssets from "../../api/assets";

const RentOrder = (prop: any) => {
  const { item, id } = prop;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const UpdateMutation = useMutation(apiRentOrder.update);

  const onFinish = async (values: any) => {
    const data = { ...values };

    await UpdateMutation.mutateAsync({
      ...data,
      id,
    });
    message.success("Амжилттай засагдлаа !");
    prop?.close();
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({ ...item });
    }
  }, [item]);
  return (
    <Spin spinning={UpdateMutation.isLoading}>
      <div className="pt-10">
        <Form form={form} onFinish={onFinish} name="rent-order">
          <div className="space-y-5 mb-5">
            <Form.Item name="receivedDate">
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold ">
                  Илгээгдсэн огноо:
                </div>
                <div className="text-md">{prop.item?.receivedDate}</div>
              </div>
            </Form.Item>
            <Form.Item name="property">
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold ">Байрны нэр:</div>
                <div className="text-md">{prop.item?.property}</div>
              </div>
            </Form.Item>
            <Form.Item name="name">
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold ">Нэр:</div>
                <div className="text-md">{prop.item?.name}</div>
              </div>
            </Form.Item>
            <Form.Item name="emailAddress">
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold">И-Мэйл:</div>
                <div className="text-md">{prop.item?.emailAddress}</div>
              </div>
            </Form.Item>
            <Form.Item name="phoneNumber">
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold ">Утас:</div>
                <div className="text-md">{prop.item?.phoneNumber}</div>
              </div>
            </Form.Item>

            <Form.Item name="message">
              <div className="flex space-x-5 items-center">
                <div className="text-[14px] font-semibold">Мессеж:</div>
                <div className="text-md  break-words w-10/12">
                  {prop.item?.message}
                </div>
              </div>
            </Form.Item>
          </div>
          <Form.Item
            className="font-semibold items-center"
            label={
              <div className="text-[14px] font-semibold mb-2">Тэмдэглэл</div>
            }
            name="note"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
              <div className="flex space-x-5 items-start  justify-end">
                <Button
                  size="large"
                  onClick={() => {
                    prop?.close();
                    form.resetFields();
                  }}
                  className="bg-gray-300 px-5  cursor-pointer"
                >
                  Болих
                </Button>

                <Button size="large" type="primary" htmlType="submit">
                  Хадгалах
                </Button>
              </div>
            </Form.Item>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};
export default RentOrder;
