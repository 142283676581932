import React from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
interface IProps {
  title: string;
  back?: boolean;
  suffix?: React.ReactChild;
}

const Title = ({ title, back, suffix }: IProps) => {
  let history = useHistory();
  return (
    <section className="flex items-center justify-between space-x-4 my-5">
      <div className="flex items-center space-x-4">
        {back && (
          <ArrowLeftOutlined
            className="cursor-pointer"
            onClick={() => history.goBack()}
          />
        )}{" "}
        <span className="text-xl font-semibold uppercase">{title}</span>
      </div>
      <div>{suffix}</div>
    </section>
  );
};

export default Title;
