import React, { createRef, useEffect, useRef, useState } from "react";
import { Input, Button, Form, message, Radio, Space, InputNumber } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";
import apiDryBranch from "../../api/dryBrancg";

const DryBranchFrom = (prop: any) => {
  const { data, id } = prop;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const dryMutation = useMutation(
    id ? apiDryBranch.update : apiDryBranch.insert,
    {
      onSuccess: () => {
        message.success("Амжилттай хадгалагдлаа");
        prop?.close();
        form.resetFields();
      },
      onError: (e: any) => {
        message.error(e?.message || "Хүсэлт явуулах үед алдаа гарлаа!");
      },
      onSettled: () => {
        queryClient.invalidateQueries("dry");
      },
    }
  );

  const onFinish = async (values: any) => {
    const data = { ...values };
    dryMutation.mutate({ id: id, ...data });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    } else {
      form.resetFields();
    }
  }, [data]);
  return (
    <div className="py-10">
      <Form
        form={form}
        name="dryBranch"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{ required: "Та бөглөх шаардлагатай!" }}
      >
        <Form.Item
          name="name"
          label={<div className="text-lg font-semibold mb-2">Гарчиг</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="schedule"
          label={
            <div className="text-lg font-semibold mb-2">Цагийн хуваарь</div>
          }
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<div className="text-lg font-semibold mb-2">Утас</div>}
          name="phoneNumber"
          rules={[{ required: true }]}
        >
          <InputNumber
            placeholder="0"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label={<div className="text-lg font-semibold mb-2">Хаяг</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="coordinateX"
          label={<div className="text-lg font-bold mb-2">Уртраг</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="coordinateY"
          label={<div className="text-lg font-bold mb-2">Өргөрөг</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="url"
          label={<div className="text-lg font-bold mb-2">Url хаяг</div>}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <div className="flex space-x-5 items-start  justify-end">
            <Button
              size="large"
              onClick={() => {
                prop?.close();
                form.resetFields();
              }}
              className="bg-gray-300 px-5  cursor-pointer"
            >
              Болих
            </Button>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button size="large" type="primary" htmlType="submit">
                Хадгалах
              </Button>
            </Form.Item>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default DryBranchFrom;
