import { message, Tooltip } from "antd";
import * as React from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { ImUpload } from "react-icons/im";
interface IProps {
  onChange?: (file: any) => void;
  handle?: (file: any) => void;
  name?: string;
  value?: any;
  remove?: () => void;
}

const Upload = (props: IProps) => {
  const { value, name, remove } = props;
  const [file, setFile] = React.useState<any>();
  const [preview, setPreview] = React.useState<any>();

  const handlePreview = (file: any) => {
    if (file?.name) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setPreview(reader.result);
      };
      reader.onerror = () => {
        alert("Уучлаарай алдаатай файл байна !");
      };
    }
  };

  React.useEffect(() => {
    if (props?.handle && props.onChange && file) {
      props?.handle(file);
      props.onChange(file ? true : undefined);
    }
  }, [file]);

  React.useEffect(() => {
    if (value && typeof value === "string") {
      // props?.handle(value);
      setPreview(`${value}`);
      if (props.handle) {
        props.handle(value);
      }
      if (props.onChange) {
        props.onChange(value ? true : undefined);
      }
    }
  }, [value]);

  const handleChange = (e: any) => {
    const [file] = e.target.files;
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size <= 2) {
        handlePreview(file);
        setPreview(null);
        setTimeout(() => {
          setFile(file);
          handlePreview(file);
        }, 100);
      } else {
        message.warn(
          `Уучлаарай таны файлын хэмжээ ${size?.toFixed(
            2
          )}mb байна. 2mb аас бага байх ёстой!`
        );
      }
    } else {
      setFile(null);
      handlePreview(null);
    }
  };

  return (
    <>
      <div className="w-full border-2 relative h-20 group flex items-center justify-center hover:bg-gray-100/10 border-dashed p-5 text-center hover:border-blue-500/50 rounded-md">
        <input
          type="file"
          accept="image/*"
          name="file"
          onChange={handleChange}
          // onChange={(e) => {
          //   const [file]: any = e.target.files;
          //   setPreview(null);
          //   setTimeout(() => {
          //     if (file?.name) {
          //       setFile(file);
          //       handlePreview(file);
          //     } else {
          //       setFile(null);
          //       handlePreview(null);
          //     }
          //   }, 100);
          // }}
          className="absolute top-0 w-full h-full cursor-pointer left-0 right-0 bottom-0 opacity-0"
        />
        <button
          type="button"
          className="rounded-full  flex flex-col justify-center items-center px-10 py-2 bg-primary group-hover:text-blue-500 text-black/50"
        >
          <div className="flex items-center space-x-2">
            <ImUpload size={20} /> <span>Файл сонгох</span>
          </div>
          {file && (
            <div className="text-center w-full font-semibold text-xs">
              {file.name}
            </div>
          )}
        </button>

        <Tooltip title="Устгах">
          <div
            onClick={() => {
              setFile(null);
              // props.onChange(undefined);
              setPreview(null);
              remove && remove();
            }}
            className=" h-10 top-1 bg-white group cursor-pointer right-1 absolute flex items-center justify-center"
          >
            <AiFillCloseSquare
              className={`text-red-500 group-hover:opacity-80`}
              size={36}
            />
          </div>
        </Tooltip>
      </div>
      {preview && (
        <div className="border-2 w-full border-t-0 border-dashed  relative -top-1 p-2">
          <img className="w-full" src={preview} />
          {/* <Tooltip title="Устгах">
            <div
              onClick={() => {
                setFile(null);
                // props.onChange(undefined);
                setPreview(null);
                remove && remove();
              }}
              className=" h-10 top-1 bg-white group cursor-pointer right-1 absolute flex items-center justify-center"
            >
              <AiFillCloseSquare
                className={`text-red-500 group-hover:opacity-80`}
                size={36}
              />
            </div>
          </Tooltip> */}
        </div>
      )}
    </>
  );
};

export default Upload;
